<template lang="pug">
.write-off-table
  v-card
    .write-off-table__header
      .write-off-table__header-column {{ 'pages.storage.accounting_types.title' | translate }}
      .write-off-table__header-column {{ 'base.name' | translate }}
      .write-off-table__header-column {{ 'pages.storage.amount_write_off' | translate }} [{{ 'units.kg' | translate }}]
      .write-off-table__header-column {{ 'pages.storage.price_write_off' | translate }}
      .write-off-table__header-column {{ 'base.ration' | translate }}
      .write-off-table__header-column {{ 'base.recipe' | translate }}
      .write-off-table__header-column {{ 'base.date' | translate }}

  v-table-body(
    gap
    outside-table
    :cols="7"
    :loading="loading"
    :empty="!writeOffs.length")
    expense-table-row(
        v-for="writeOff in writeOffs"
        :key="writeOff.id"
        :writeOff="writeOff"
        @click="showDetailsModal")

  v-card(
    v-if="!loading && showPagination")
    v-paginate(
      v-model="page"
      :page-count="pageCount"
      :click-handler="pageChangeHandler")

</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import paginationMixin from '@/mixins/pagination.mixin'
  import ExpenseTableRow from './ExpenseTableRow'
  import DetailsModal from './modals/DetailsModal'

  export default {
    name: 'ExpenseTable',

    mixins: [paginationMixin],

    components: {
      ExpenseTableRow,
      DetailsModal
    },

    data: () => ({
      currentItem: null,
      modal: {
        showDetailsModal: false
      }
    }),

    mounted () {
      this.setupPagination(this.fetchWriteOffs)
    },

    methods: {
      ...mapActions(['fetchWriteOffs']),

      showDetailsModal (item) {
        this.currentItem = item
        this.modal.showDetailsModal = true
      }
    },

    computed: {
      ...mapGetters([
        'writeOffs',
        'writeOffsFilters'
      ]),

      filters () {
        return this.writeOffsFilters
      }
    },

    watch: {
      filters () {
        this.page = 1
        this.loadData()
      }
    }
  }
</script>

<style lang="scss" scoped>

@import '@/sass/abstracts/_variables.scss';
@import '@/sass/components/_info-tooltip.scss';
@import '@/sass/base/_transition.scss';

.write-off-table {
  font-size: 1.5rem;

  &__lock-overlay {
    position: absolute;
    width: 100%;
    top: 5rem;
    bottom: 0;
    background-color: rgba($color-white, .6);
    z-index: 100;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  &__header {
    height: 5.1rem;
    padding: .8rem 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    &-column {
      color: $color-gray;
      overflow: hidden;
      width: 17%;

      &:nth-child(1) {
          width: 50px;
          min-width: 50px;
      }
      &:nth-child(5) {
        width: 30%;
      }

      &:nth-child(6) {
        width: 30%;
      }

      &:nth-child(7) {
        width: 30%;
      }
    }
  }
}

.pagination {
  padding: 2rem;
}

</style>
